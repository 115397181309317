<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col sm="6" md="8">
        <div>
          <h1>CUSTOMER INSIGHTS</h1>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EvaluationDetail',
                params: { id: $route.params.id },
              })
            "
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <customer-info />

    <v-row>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" sm="12" md="12">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    label="Pace"
                    v-model="customerInsight_info.fastPace"
                    :items="fastPace_values"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    label="Focus"
                    v-model="customerInsight_info.taskFocused"
                    :items="taskFocused_values"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Age"
                    v-model="customerInsight_info.age"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-select
                    label="Gender"
                    v-model="customerInsight_info.gender"
                    :items="gender_values"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Race / Ethnicity"
                    v-model="customerInsight_info.race"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="ESL"
                    v-model="customerInsight_info.esl"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-select
                    label="Maritial Status"
                    v-model="customerInsight_info.maritialStatus"
                    :items="maritialStatus_values"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Job Title"
                    v-model="customerInsight_info.jobTitle"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-select
                    label="Profession"
                    v-model="customerInsight_info.profession"
                    :items="profession_values"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Employer"
                    v-model="customerInsight_info.employer"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Est Income"
                    v-model="customerInsight_info.estIncome"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Est Credit Score"
                    v-model="customerInsight_info.estCreditScore"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Est Education"
                    v-model="customerInsight_info.estEducation"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Hometown"
                    v-model="customerInsight_info.homeTown"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="High School"
                    v-model="customerInsight_info.highSchool"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="College"
                    v-model="customerInsight_info.college"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Military"
                    v-model="customerInsight_info.military"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Lawsuits"
                    v-model="customerInsight_info.lawsuits"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Bankruptcy"
                    v-model="customerInsight_info.bankruptcy"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Criminal Record"
                    v-model="customerInsight_info.criminalRecord"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.prolificReviewer"
                    label="Prolific Reviewer"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Facebook URL"
                    v-model="customerInsight_info.fbURL"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Instagram URL"
                    v-model="customerInsight_info.instaURL"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="LinkedIn URL"
                    v-model="customerInsight_info.linkURL"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Covid Concerned"
                    v-model="customerInsight_info.covidConcerned"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Passive"
                    v-model="customerInsight_info.passive"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.aggressive"
                    label="Aggressive"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.sportsFan"
                    label="Sports Fan"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Leans Right"
                    v-model="customerInsight_info.leansRight"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Leans Left"
                    v-model="customerInsight_info.leansLeft"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.youngKids"
                    label="Young Kids"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.teenagers"
                    label="Teenagers"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Grandkids"
                    v-model="customerInsight_info.grandKids"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Pets – Dog"
                    v-model="customerInsight_info.dogs"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.cats"
                    label="Pets – Cat"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.otherPets"
                    label="Pets – Other"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Fitness"
                    v-model="customerInsight_info.fitness"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Party"
                    v-model="customerInsight_info.party"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.foodie"
                    label="Foodie"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.environmental"
                    label="Environmental"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Motorcycle"
                    v-model="customerInsight_info.motorcycle"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Car Enthusiast"
                    v-model="customerInsight_info.enthusiast"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.gunOwner"
                    label="Gun Owner"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.womenGroup"
                    label="Women Group"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="LGBTQ"
                    v-model="customerInsight_info.lgBtq"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Artist"
                    v-model="customerInsight_info.artist"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.landscaping"
                    label="Gardening/Landscaping"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.religious"
                    label="Religious"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Boating"
                    v-model="customerInsight_info.boating"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Fishing/Hunter"
                    v-model="customerInsight_info.hunting"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.movies"
                    label="Movies/Television"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.substanceUse"
                    label="Substance Use"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Skimpy Photos"
                    v-model="customerInsight_info.photos"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Memes"
                    v-model="customerInsight_info.memes"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.joker"
                    label="Joker/Humor"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.musician"
                    label="Musician"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Concert Goer"
                    v-model="customerInsight_info.concert"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Business"
                    v-model="customerInsight_info.business"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.travel"
                    label="Travel/Vacation"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.hgTV"
                    label="HGTV"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="KU"
                    v-model="customerInsight_info.ku"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="MU"
                    v-model="customerInsight_info.mu"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.ksu"
                    label="KSU"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.royals"
                    label="Royals"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Chiefs"
                    v-model="customerInsight_info.chiefs"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Sporting KC"
                    v-model="customerInsight_info.kc"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.nascar"
                    label="NASCAR"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.golf"
                    label="Golf"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Tennis"
                    v-model="customerInsight_info.tennis"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="NBA"
                    v-model="customerInsight_info.nba"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.nhl"
                    label="NHL"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.parenting"
                    label="Parenting"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Self help"
                    v-model="customerInsight_info.selfHelp"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Rural"
                    v-model="customerInsight_info.rural"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.urbanite"
                    label="Urbanite"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.trendy"
                    label="Trendy"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Conservative"
                    v-model="customerInsight_info.conservative"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    label="Home Aesthetics"
                    v-model="customerInsight_info.aesthetics"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-checkbox
                    v-model="customerInsight_info.groomed"
                    label="Groomed / Fashionable"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "CustomerInsight.vue",
  components: { CustomerInfo },
  data() {
    return {
      evaluation_id: this.$route.params.id,
      valid: false,
      fastPace_values: ["Fast", "Slow"],
      taskFocused_values: ["People", "Task"],
      gender_values: ["Male", "Female"],
      maritialStatus_values: ["Single", "Married", "Widowed"],
      profession_values: [],
      customerInsight_info: {
        fastPace: "Fast",
        taskFocused: "Task",
        age: "45",
        gender: "Male",
        race: "Caucasian",
        military: "Army Vet",
        fbURL: "www.facebook.com/joecustomer",
        maritialStatus: "",
        profession: "",
        employer: "",
        estIncome: "",
        estCreditScore: "",
        estEducation: "",
        homeTown: "",
        highSchool: "",
        college: "",
        lawsuits: "",
        criminalRecord: "",
        bankruptcy: "",
        prolificReviewer: true,
        groomed: true,
        aesthetics: true,
        conservative: true,
        trendy: true,
        urbanite: true,
        rural: true,
        selfHelp: true,
        parenting: true,
        nhl: true,
        nba: true,
        tennis: true,
        golf: true,
        nascar: true,
        kc: true,
        chiefs: true,
        royals: true,
        ksu: true,
        mu: true,
        ku: true,
        hgTV: true,
        travel: true,
        business: true,
        concert: true,
        musician: true,
        joker: true,
        memes: true,
        photos: true,
        substanceUse: true,
        movies: true,
        hunting: true,
        boating: true,
        religious: true,
        landscaping: true,
        artist: true,
        lgBtq: true,
        womenGroup: true,
        gunOwner: true,
        enthusiast: true,
        motorcycle: true,
        environmental: true,
        foodie: true,
        party: true,
        fitness: true,
        otherPets: true,
        cats: true,
        dogs: true,
        grandKids: true,
        teenagers: true,
        youngKids: true,
        leansLeft: true,
        leansRight: true,
        sportsFan: true,
        aggressive: true,
        passive: true,
        covidConcerned: true,
        linkURL: true,
        instaURL: true,
      },
    };
  },
};
</script>

<style scoped></style>
